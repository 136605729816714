@font-face {
  font-family: "Candara";
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: url("./components/assets/fonts/Candara/Candara.ttf") format("truetype");
}
@font-face {
  font-family: "Goldenbook Light";
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: url("./components/assets/fonts/GoldenbookFont/TTF/Goldenbook-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Snell";
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: url("./components/assets/fonts/SnellRoundhand/SnellBT-Regular.otf") format("opentype");
}

html {
  font-family: "Candara", Verdana, Arial, sans-serif;
  font-size: 18px;
  color: #FFF;
  background-color:#000;
}

body {
  font-family: "Candara", Verdana, Arial, sans-serif;
  font-size: 18px;
  color: #FFF;
  background-color:#000;
  padding-top: 135px;
  @media screen and (max-width: 765px) {
    padding-top: 100px;
  }
}

.desktop {
  @media screen and (min-width: 525px) {
    display: block;
    margin: 0 15px;
  }
  display: none;
}

.mobile {
  @media screen and (max-width: 525px) {
    display: block;
  }
  display: none;
}

.container {
  z-index: 1;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.table-white {
  background-color: #FFF;
}

.table {
  background-color: #fff;
  color: #000;
}

.table-dark {
  background-color: #343a40;
  color: #fff;
}

.table a {
  color: #000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(000,000,000,0.5); 
    filter: blur(8px);
    -webkit-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 1; 
    display: block;
}

.overlay.none {
  display: none;
}

.dropdown-menu {
  background-color: #000;
  color: #FFF;
}

a#basic-nav-dropdown {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu.show {
  @media screen and (max-width: 768px) {
    will-change: transform;
    left: 0px;
    transform: translate3d(0px, 0px, 0px);
  }
  // @media screen and (max-width: 525px) {
  //   left: -65px;
  //   transform: translate3d(0px, 0px, 0px);
  // }
}

.dropdown-item {
  color: #fff;
  font-size: 30px;
  padding: 5px 40px;
}

/* VIDEO BG */
#videobg {
  position: fixed;
  z-index: 0;
  width: 55%;
  opacity: 0.1;
  bottom: 0;
  right: 0;
}

.video {
  // max-width: 800px;
  width: 100%;
  margin-bottom: 20px;
  iframe {
    width: 100%;
    // height: 100%;
  }
}

body.light {
  background-color: #fff;
  color: #000;
}

main {
  min-height: 80vh;
}

// @media (max-width: 525px) {
//   .navbar .container {
//     flex-direction: column;
//   }
// }

.menu-shadow {
  backdrop-filter: blur(9px);
  box-shadow: 2px 1px 6px rgba(0,0,0,.5);
  padding: 1rem 0rem;
}
@media screen and (max-width: 525px) {
  .menu-shadow, .menu-shadow>.container{
    padding-bottom: 0;
  }
  
}
.navbar {
  z-index: 2;
  justify-content: end;
  text-transform: capitalize;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0;
  }
}

#basic-navbar-nav {
  padding: 0;
  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
}

.nav-item {
  margin-right: 0;
}

.navbar-brand {
  margin-right: 0;
  // display: none;
}

.navbar-dark .container {
  justify-content: center;
}

@media (max-width: 767px) {
  .navbar-dark .container {
    justify-content: space-between;
    padding: 0 1rem;
  }
}

.navbar-nav {
  align-items: center;
  @media  (max-width: 767px) {
    text-align: left;
    align-items: normal;
  }
  a {
    padding: 0 10px;
    @media  (max-width: 767px) {
      font-size: 35px;
      &.active {
        border-bottom: 0px solid;
      }
    }
    &.active {
      border-bottom: 1px solid;
      width: fit-content;
    }
  }
}

span.asterix {
  font-size: 12px;
  font-style: italic;
}


// Mobile
// @media (max-width: 525px) {
//   .navbar {
//     padding: 0;
//   }
//   .navbar-brand {
//     padding: 0;
//     display: block;
//   }
//   .navbar-brand img {
//     width: 100%;
//   }
//   .navbar-nav {
//     padding: 0;
//   }
//   .nav-item {
//     padding: 0;
//   }
//   .nav-link {
//     padding: 0;
//   }
//   .navbar-toggler {
//     padding: 0;
//   }
//   .navbar-toggler-icon {
//     padding: 0;
//   }
// }

.navbar-light .navbar-brand {
  color: #fff;
}

.dropdown-toggle::after {
  display: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #FFF;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

hr {
  border-top: 1px solid rgba(255,255,255,0.2);
}

a {
  color: #fff;
}
a:hover {
  color: #fff;
  // border-bottom: 1px solid;
  text-decoration: none !important;
}

.nav-tabs .nav-link.active {
  color: #55595E !important;
}

a.nav-link {
  color: #fff !important;
}

a.dark-link {
  color: #000;
}
a:hover.dark-link {
  color: #000;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .text-center-mobile {
    text-align: center;
  }
  .mb-50-mobile {
    margin-bottom: 50px;
  }
  
}

.copyright {
  font-size: 12px;
}

button {
  border: 1px solid;
  border-radius: 10px;
  color: #fff;
  background-color: initial;
  padding: 5px 20px;
  text-transform: uppercase;
  margin-right: 10px;
}

.paystack-button {
  background-color: #1A1A1A;
  border: 1px solid #1A1A1A;
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  margin-right: 10px;
  max-width: 750px;
}

// .mark-delivered {
//   margin-top: 100px;
// }

.mark-cancelled {
  background-color: #cb493a;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

.large-h2 {
  font-size: 2.5em;
}
@media (max-width: 700px) {
  .large-h2 {
    font-size: 1.5em;
  }
}
.dark {
  color: #000;
}
.dark:hover {
  color: #000;
}
.row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.trans-bg {
  background-color: rgba(255,255,255,0.7);
  color: #000;
}
.trans-bg h1 {
  color: #000;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.m-50 {
  margin: 50px 0px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}
.no-mt {
  margin-top: 0;
}
.no-pb {
  padding-bottom: 0;
}
.no-pt {
  padding-top: 0;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .mb-20-md {
    margin-bottom: 20px !important;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

/* Card Details */
.card {
  background-color: inherit;
  border: none;
}
.crop-200 {
  height: 200px;
  object-fit: cover;
}
/* .card-text {
  color: #000;
}
.card-body {
  color: #000 !important;
}
.card-body a {
  color: #000;
} */

.card-body a:hover {
  text-decoration: none;
}
.list-group-item {
  background-color: inherit;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding:0;
}

/* flex */
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .justify-content-center-md {
    display: flex;
    justify-content: center !important;
  }
}

input.form-control {
  border-bottom: 2px solid #A7A9AC;
}

textarea.form-control {
  background: #fff;
  border-bottom: 2px solid #A7A9AC;
}

.center {
  margin: 0 auto;
  display: block;
}

.navbar-collapse {
  flex-grow: unset;
}

.footerFeather {
  float: right;
}

@media screen and (max-width: 768px) {
  .footerFeather {
    float: none;
  }
}

.footer-logo {
  max-width: 350px;
  width: 100%;
}

/* CAROUSEL */
.slick-prev {
  left: -35px;
}
.slick-next {
  right: -35px !important;
}
.slick-slide img {
  height: 500px;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    height: 300px;
  }
}

/*  */
header {
  z-index: 2;
  position: fixed;
  width: 100%;
  background-color: rgba(000,000,000,0.5);
  top: 0;
  .container {
    padding: 0;
    display: flex;
    justify-content: space-between;
    // Mobile
    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-bottom: 3%;
    }
  }
  // Center the logo
  .logo {
    @media screen and (max-width: 768px) {
      position: absolute;
      pointer-events: all;
      z-index: 3;
    }
    // a {
    //   z-index: 2;
    //   position: relative;
    // }
  }
}

/*  */
footer {
  .text-right {
    text-align: right;
    @media screen and (max-width: 768px) {
      text-align: center !important;
    }
  }
  @media screen and (max-width: 768px) {
    text-align: center !important;
  }
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.login-logout {
  line-height: 35px;
}

.text-left {
  text-align: left;
}

// Flexbox
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}

.about-padding .history {
  padding-right: 50px;
  text-align: justify !important;
}
.about-padding .mission {
  padding-left: 50px;
  text-align: justify !important;
}

@media (max-width: 768px) { 
  .about-padding .history {
    padding-right: 0px;
    text-align: center !important;
  }
  .about-padding .mission {
    padding-left: 0px;
    text-align: center !important;
  }
}

// Mobile flexbox
@media (max-width: 768px) {
  .flex-md {
    display: flex;
  }
  .flex-row-md {
    flex-direction: row;
  }
  .flex-column-md {
    flex-direction: column;
  }
  .flex-wrap-md {
    flex-wrap: wrap;
  }
  .flex-nowrap-md {
    flex-wrap: nowrap;
  }
  .flex-1-md {
    flex: 1;
  }
  .flex-2-md {
    flex: 2;
  }
  .flex-3-md {
    flex: 3;
  }
  .flex-4-md {
    flex: 4;
  }
}

// Products styling
.product {
  margin-bottom: 50px;
  .product-image {
    max-width: 500px;
    margin-right: 25px;
    width: 100%;
  }
  .product-image img {
    width: 500px;
    @media (max-width: 768px) {
      width: 100%;
    }
    
  }
  .product-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .product-description {
    margin-top: 15px;
  }
}

.quote {
  font-family: "Snell", serif;
  text-align: center;
  font-size: 40px;
  text-transform: none;
  font-style: italic;
  .twi_3 {
    transform: scale(-1, -1);
    display: inline-block;
    position: relative;
    left: -20px;
    top: 3px;
  }
  .twi_C {
    transform: scale(-1, 1) rotate(-69deg);
    display: inline-block;
    position: relative;
    left: -19px;
    top: -5px;
  }
}